import { combineReducers, applyMiddleware } from "redux";
import { createStore } from "redux";
import thunk from "redux-thunk";

const theme = (state = localStorage.getItem('mode') ? localStorage.getItem('mode') : 'light', action) => {
	switch (action.type) {
		case 'SET_THEME':
			localStorage.setItem('mode', action.theme)
			return action.theme
		default:
			return state
	}
}

const alert = (state = {}, action) => {
  switch (action.type) {
    case "SET_ALERT":
      var new_alert = {
        open: action.alert.open,
        severity: action.alert.severity
          ? action.alert.severity
          : state.severity,
        message: action.alert.message ? action.alert.message : state.message,
        vertical: action.alert.vertical ? action.alert.vertical : null,
        horizontal: action.alert.horizontal ? action.alert.horizontal : null,
      };
      return new_alert;
    default:
      return state;
  }
};

const user = (state = null, action) => {
  switch (action.type) {
    case "SET_USER":
      if (action.user && action.user.token) {
        localStorage.setItem("token", action.user.token);
      }
      return action.user;
    case "SET_USER_SOCKET":
      return Object.assign({}, state, {
        socket: action.socket,
      });
    case "SIGN_OUT":
      localStorage.removeItem("mode");
      localStorage.removeItem("token");
      localStorage.removeItem("parent_token");
      return null;
    default:
      return state;
  }
};

const temp = (state = {}, action) => {
  switch (action.type) {
    case "SET_TEMP":
      return action.temp;
    default:
      return state;
  }
};

const crypto = (
  state = {
    private_key: ``,
    passphrase: ``,
	public_key: ``,
	public_key_id: '',
    hash: '',
    name: ``,
    email: '',
    recipient: '',
    sender: '',
	signature: '',
	extended_message: '',
	verification: {},
	verified: null,
	input: '',
	output: '',
	decrypted: '',	
	decrypted_signed: '',
  bits: 4096,
  creation_time: '',
  comments: '',
  registry_entry: '',
  stored: false,
  lookupMark: '',
  lookupKey: '',
  lookupKeyFound: '',
  lookupKeyFingerprint: '',
  keyFound: '',
  notFound: '',
  mark_id: '',
  foundMark: ''
  },
  action
) => {
  switch (action.type) {
    case "SET_CRYPTO":
      return action.crypto;
    default:
      return state;
  }
};

const store = createStore(
  combineReducers({
    theme,
    alert,
    user,
    temp,
    crypto,
  }),
  applyMiddleware(thunk)
);

export default store;
