import React from 'react'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { connect } from 'react-redux'
import { setTemp } from './actions'

function Confirm(props) {	

	const handleClose = () => {

if (props.temp.confirm && props.temp.confirm.prevent_close) {
	return false
}
		
		var temp = Object.assign({}, props.temp, {
			confirm: null,
		})

        if (props.temp.confirm.cancel) {
            props.temp.confirm.cancel()
        }

        props.setTemp(temp)
    

	}

	return (
		<Dialog
			open={props.temp.confirm ? true : false}
			onClose={handleClose}
			aria-labelledby="form-dialog-title"
            fullWidth
            maxWidth="xs"
		>
			<DialogTitle style={{ paddingBottom: '8px' }}>
				{props.temp.confirm && props.temp.confirm.title
					? props.temp.confirm.title
					: ''}
			</DialogTitle>
			<DialogContent style={{ paddingTop: '0px' }}>
				<Typography>
					{props.temp.confirm && props.temp.confirm.text
						? props.temp.confirm.text
						: 'Are you sure?'}
				</Typography>
			</DialogContent>
			{props.temp.confirm && !props.temp.confirm.hide_buttons ? <DialogActions>
				<Button onClick={handleClose} color="secondary">
				{props.temp.confirm && props.temp.confirm.cancel_text ? props.temp.confirm.cancel_text : 'Cancel'}
				</Button>

				<Button onClick={() => {
                    if (props.temp.confirm && props.temp.confirm.callback) {
                        handleClose()
                        props.temp.confirm.callback()
                    } 
                }} color="primary">
					{props.temp.confirm && props.temp.confirm.confirm_text ? props.temp.confirm.confirm_text : 'Yes'}
				</Button>
			</DialogActions> : <DialogActions>
			
			</DialogActions>}
		</Dialog>
	)
}

const mapStateToProps = (state) => ({	
	temp: state.temp,
})

const mapDispatchToProps = (dispatch) => ({	
	setTemp: (temp) => dispatch(setTemp(temp)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Confirm)
