import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { setUser, setAlert, setTemp, setCrypto, setTheme } from "../actions";
import { Grid, Box } from "@material-ui/core";
import Lookup from "./Lookup";
import LookupKey from "./LookupKey";
//import Submit from "./Submit";
import Keys from "./Keys";
import Output from "./Output";
import Theme from "../ThemeSwitch";
//import Hash from "./Hash";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Credentials from "../Credentials";
import Generate from "./Generate";

var use_theme = null;

const useStyles = makeStyles(
  (theme) => {
    use_theme = theme;
    return {
      box: {
        width: "100%",
      },
      sub: {
        marginTop: theme.spacing(2),
      },
      next: {
        marginTop: theme.spacing(0.5),
      },
      low: {
        marginTop: theme.spacing(3.5),
      },
      qr: {
        width: "100%",
      },
    };
  },
  { withTheme: true }
);

function Check(props) {
  const classes = useStyles();

  const matches = use_theme.breakpoints.up("lg");
  var isLg = useMediaQuery(matches);

  return (
    <React.Fragment>
      <Box p={1} pl={1} pr={1} pb={0}>
        <Grid container spacing={2} style={{ margin: "0px", width: "100%" }}>
          <Grid
            container
            item
            alignItems="flex-start"
            alignContent="flex-start"
            xs={12}
            lg={3}
          >
            <div>
              <Grid item xs={12}>
                <Lookup />
              </Grid>

              <Grid item xs={12}>
                <div className={classes.low}>
                  <LookupKey />
                </div>
              </Grid>
              <Credentials noSwitch={false} />
            </div>
            {/*
              <div className={classes.sub}>
                <Hash />
              </div>
    */}
          </Grid>
          <Grid container item xs={12} lg={4}>
            <Box mt={0} style={{ width: "100%" }}>
              <Keys public={false} />

              <div className={classes.next} style={{ width: "100%" }}>
                <Generate verify={true} />
              </div>

              <div className={classes.sub}>
                <Output public={false} />
              </div>
            </Box>
          </Grid>

          {(!props.crypto.registeredKey &&
            !props.crypto.signedMark &&
            props.crypto.mark_id &&
            props.crypto.qr) ||
          ((props.crypto.registeredKey || props.crypto.signedMark) &&
            props.crypto.mark_id &&
            props.crypto.qr &&
            isLg) ? (
            <Grid
              container
              item
              alignItems="flex-start"
              alignContent="flex-start"
              xs={12}
              lg={2}
              spacing={0}
            >
              <Grid container>
                <Grid item xs={12} sm={4} lg={12}>
                  <div style={{ width: "100%" }}>
                    {props.crypto.qr ? (
                      <img
                        src={props.crypto.qr}
                        className={classes.qr}
                        alt={"qr code for " + props.crypto.mark_id}
                      />
                    ) : null}
                  </div>

                  <div
                    style={{
                      fontFamily: "monospace",
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    <a
                      href={
                        "https://" +
                        process.env.REACT_APP_DOMAIN +
                        "/pub/" +
                        props.crypto.mark_id
                      }
                      target={"_" + props.crypto.mark_id}
                    >
                      {props.crypto.mark_id}
                    </a>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </Box>

      <Box mt={0} p={2} pt={1}>
        <div style={{ width: "100%", textAlign: "right" }}>
          <Theme />
        </div>
      </Box>

      {props.crypto.signedMark || props.crypto.registeredKey ? (
        <Box mt={1} p={2} pt={0}>
          {props.crypto.registeredKey ? (
            <Grid item xs={12}>
              <div
                dangerouslySetInnerHTML={{
                  __html: props.crypto.registeredKey,
                }}
              />
            </Grid>
          ) : null}

          {props.crypto.signedMark ? (
            <Grid item xs={12}>
              <div
                dangerouslySetInnerHTML={{ __html: props.crypto.signedMark }}
              />
            </Grid>
          ) : null}
        </Box>
      ) : null}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  temp: state.temp,
  crypto: state.crypto,
  theme: state.theme,
});

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(setUser(user)),
  setAlert: (alert) => dispatch(setAlert(alert)),
  setTemp: (temp) => dispatch(setTemp(temp)),
  setCrypto: (crypto) => dispatch(setCrypto(crypto)),
  setTheme: (theme) => dispatch(setTheme(theme)),
  changeCrypto: (callback, crypto) => {
    dispatch((dispatch) => {
      callback(dispatch(setCrypto(crypto)).crypto);
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Check);
