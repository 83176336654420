import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { connect } from 'react-redux'
import { setUser, setAlert, setTemp, setCrypto } from "../actions";
//import store from "store"

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: '0px',
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function SimpleSelect(props) {
  const classes = useStyles();

  const handleChange = (e) => {


    var new_crypto = Object.assign({}, props.crypto);    
    new_crypto.stored = false
    new_crypto.changed = true
    new_crypto.bits = e.target.value
        
if (window.typing) {
clearTimeout(window.typing)
}
window.typing = setTimeout(()=>{
//store.remove('crypto')
props.setCrypto(new_crypto)
},250)


  };

  return (
    <div>
      <FormControl variant={process.env.REACT_APP_STYLE} className={classes.formControl} margin="dense" fullWidth>
        <InputLabel>bits</InputLabel>
        <Select
          value={props.crypto.bits}
          onChange={handleChange}
          label="bits"
        >
          <MenuItem value={2048}>2048</MenuItem>
          <MenuItem value={4096}>4096</MenuItem>          
        </Select>
      </FormControl>
    </div>
  );
}


const mapStateToProps = (state) => ({
	user: state.user,
    temp: state.temp,
    crypto: state.crypto
})

const mapDispatchToProps = (dispatch) => ({
	setUser: (user) => dispatch(setUser(user)),
	setAlert: (alert) => dispatch(setAlert(alert)),
    setTemp: (temp) => dispatch(setTemp(temp)),
    setCrypto: (crypto) => dispatch(setCrypto(crypto)),
    changeCrypto: (callback, crypto) => {
      dispatch((dispatch) => {
        callback(dispatch(setCrypto(crypto)).crypto);
      });
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(SimpleSelect)