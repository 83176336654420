import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import { connect } from "react-redux";
import { setTheme, setCrypto, setTemp } from "./actions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Button, Grid } from "@material-ui/core";
import store from "store";
import axios from "axios";
import ReplayIcon from "@material-ui/icons/Replay";
import RemoveIcon from "@material-ui/icons/RemoveCircleTwoTone";
//import LockIcon from "@material-ui/icons/EnhancedEncryptionTwoTone";
import NoLockIcon from "@material-ui/icons/NoEncryptionTwoTone";
import KeyIcon from "@material-ui/icons/VpnKeyTwoTone";
import LockOpenIcon from "@material-ui/icons/LockOpenTwoTone";
import LockedIcon from "@material-ui/icons/LockTwoTone";

const CustomSwitch = withStyles((theme) => ({
  switchBase: {
    color: theme.palette.secondary.light,
  },
  track: {},
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      size="small"
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const useStyles = makeStyles(
  (theme) => ({
    switch: {
      marginLeft: "4px",
    },
    switch_container: {
      margin: "0px",
      marginTop: "16px",
    },
    switch_label: {
      marginLeft: "0px",
      marginTop: "8px",
    },
    reload: {},
    tall_button: {
      height: "100%",
    },
    remove: {
      backgroundColor: theme.palette.danger.main,
      "&:hover": {
        backgroundColor: theme.palette.danger.contrastText,
      },
    },
  }),
  { withTheme: true }
);

function Credentials(props) {
  const [lookedUpKey, setLookedUpKey] = React.useState(false);
  const [loadedCredentials, setLoadedCredentials] = React.useState(false);
  const [popped, setPopped] = React.useState(false);

  const classes = useStyles();

  var label = "store keys?";

  var isStored = store.get("crypto");

  let search = window.location.search;
  let params = new URLSearchParams(search);
  let pop = params.get("pop");

  if (isStored && !props.crypto.changed && (!pop || popped)) {
    label = "store keys?";
  }

  var lookup_key = async (new_crypto) => {
    //new_crypto.public_key_id = mark.public_key_id.trim();

    let found = await axios.post("/lookupkey", {
      key: new_crypto.public_key_id,
    });

    //new_crypto.lookupKey = new_crypto.public_key_id;



    if (found.data.key_id) {
      //new_crypto.lookupKeyFound = new_crypto.public_key_id;
      new_crypto.keyFound = found.data.key_id;
      new_crypto.registry_entry = found.data.registry_entry;
      new_crypto.notFound = false;
    } else {
      new_crypto.keyNotFound = found.data.failed;
      new_crypto.notFound = found.data.failed;
    }
    //new_crypto.stored = false
    props.setCrypto(new_crypto);

    setLoadedCredentials(true);
    setLookedUpKey(true);
  };

  const toggle = async () => {
    if (props.crypto.stored) {
      var complete_remove = async () => {
        setPopped(true);
        var new_crypto = Object.assign({}, props.crypto);
        new_crypto.stored = false;
        store.remove("crypto");
        setLoadedCredentials(false);
        await props.setCrypto(new_crypto);
      };

      var temp = Object.assign({}, props.temp, {
        confirm: {
          title: "Are you sure?",
          text: `WARNING: Your keys will no longer be stored in this browser for future use. If you do not have a copy of these keys they will be permanently lost and unrecoverable. You should make a copy of these keys and the associated passphrase now if you will need them again in the future. Are you sure you want to remove the stored keys?`,
          callback: complete_remove,
        },
      });

      props.setTemp(temp);
    } else if (isStored && !props.crypto.changed) {
      setPopped(true);

      var new_crypto = Object.assign({}, props.crypto);

      new_crypto = JSON.parse(isStored);
      new_crypto.stored = true;
      delete new_crypto.registry_entry;
      new_crypto.foundMark = props.crypto.foundMark;

      new_crypto.passphrase = "";

      if (!lookedUpKey) {
        await lookup_key(new_crypto);
      }

      //new_crypto.stored = false

      await props.setCrypto(new_crypto);
    } else {
      var complete_request = async () => {
        setPopped(true);

        var new_crypto = Object.assign({}, props.crypto);

        //if (!window.confirm("This will store your private key in this browser's local storage for future use. You can remove it at any time. Do not use this option if anyone else has access to this browser. Are you sure you want to do this?")) return false

        new_crypto.stored = true;
        var passphrase = new_crypto.passphrase
          ? new_crypto.passphrase.toString()
          : "";
        delete new_crypto.passphrase;
        delete new_crypto.changed;
        //delete new_crypto.stored;
        delete new_crypto.foundMark;
        store.set("crypto", JSON.stringify(new_crypto));
        setLoadedCredentials(true);

        if (passphrase) {
          new_crypto.passphrase = passphrase;
        } else {
          new_crypto.passphrase = "";
        }

        if (!lookedUpKey) {
          await lookup_key(new_crypto);
        }

        //new_crypto.stored = false

        await props.setCrypto(new_crypto);
      };

      var cancel_request = async () => {};

      temp = Object.assign({}, props.temp, {
        confirm: {
          title: "Are you sure?",
          text: `WARNING: This action will store a copy of the encrypted version of your private key in this browser's local storage for future use. Your private key will NOT be transmitted over the internet and you can remove stored keys at any time. The associated passphrase will NOT be stored in this browser, so make sure you have saved or memorized the passphrase. It is NOT SAFE to store keys if anyone else has access to this browser. Are you sure you want to store the keys?`,
          callback: complete_request,
          cancel: cancel_request,
        },
      });

      props.setTemp(temp);
    }
  };

  isStored = store.get("crypto");

  if (pop && !popped) {
    isStored = false;
  }

  var icon = <ReplayIcon />;

  if (isStored && !props.crypto.changed && props.crypto.stored) {
    label = "keys stored";
  }

  var stored_label =
    !loadedCredentials && ((pop && !popped) || !pop)
      ? "load stored keys"
      : "reload stored keys";

  if (stored_label === "load stored keys") {
    icon = <KeyIcon />;
  }

  if (stored_label === "reload stored keys") {
    icon = <KeyIcon />;
    var reload = true
    if (!(isStored && !props.crypto.changed && props.crypto.stored)) {
      var force_reload = true
    }

    stored_label = 'load stored keys'

  }

  if (!isStored) {
    stored_label = "no stored keys";
    icon = <NoLockIcon />;
  }

  var use_class =
    !loadedCredentials && ((pop && !popped) || !pop) ? "" : classes.reload;

  use_class += " " + classes.tall_button;

  return (
    <div className={classes.switch_container}>
      {props.crypto ? (
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <Button
              fullWidth
              onClick={async () => {
                var new_crypto = Object.assign({}, props.crypto);

                isStored = store.get("crypto");

                new_crypto = JSON.parse(isStored);
                //new_crypto.stored = true;
                delete new_crypto.registry_entry;
                new_crypto.foundMark = props.crypto.foundMark;
                new_crypto.mark_id = props.crypto.mark_id
                new_crypto.qr = props.crypto.qr
                new_crypto.sender = props.crypto.sender
                new_crypto.output = props.crypto.output
                new_crypto.signedMark = props.crypto.signedMark
                new_crypto.lookupKeyFound = props.crypto.lookupKeyFound;
                new_crypto.lookupKeyFingerprint = props.crypto.lookupKeyFingerprint;

                new_crypto.passphrase = "";

                new_crypto.refresh_keys = true;
                new_crypto.refresh_creds = true;
                new_crypto.refresh_entry = true;
                new_crypto.refresh_passphrase = "";

                await lookup_key(new_crypto);
              }}
              size="small"
              color={
                (!loadedCredentials && ((pop && !popped) || !pop))
                  ? "primary"
                  : force_reload && props.crypto.changed ? "primary" : "secondary"
              }
              variant="contained"
              className={use_class}
              disabled={(!isStored || (reload && !force_reload) || (force_reload && !props.crypto.changed))}
              startIcon={icon}
            >
              {stored_label}
            </Button>
          </Grid>

          {isStored ? (
            <Grid item xs={12} sm={6}>
              <Button
                fullWidth
                onClick={async () => {
                  var complete_remove = async () => {
                    var new_crypto = Object.assign({}, props.crypto);

                    isStored = false;

                    new_crypto.stored = false;
                    new_crypto.changed = true;
                    store.remove("crypto");
                    setLoadedCredentials(false);
                    props.setCrypto(new_crypto);
                  };

                  var temp = Object.assign({}, props.temp, {
                    confirm: {
                      title: "Are you sure?",
                      text: `WARNING: Your keys will no longer be stored in this browser for future use. If you do not have a copy of these keys they will be permanently lost and unrecoverable. You should make a copy of these keys and the associated passphrase now if you will need them again in the future. Are you sure you want to remove the stored keys?`,
                      callback: complete_remove,
                    },
                  });

                  props.setTemp(temp);
                }}
                size="small"
                variant="contained"
                className={classes.remove}
                startIcon={<RemoveIcon />}
              >
                remove stored keys
              </Button>
            </Grid>
          ) : null}
        </Grid>
      ) : null}

      {!props.noSwitch && (props.crypto.private_key || isStored) ? (
        <div>
          <FormControlLabel
            className={classes.switch_label}
            control={
              <CustomSwitch
                color="primary"
                checked={
                  isStored && !props.crypto.changed && props.crypto.stored
                    ? true
                    : false
                }
                onChange={toggle}
                value="checked"
                inputProps={{ "aria-label": "toggle stored keys" }}
                className={classes.switch}
//                checkedIcon={<LockedIcon />}
                disabled={
                  !props.crypto.private_key &&
                  !(isStored && !props.crypto.changed && props.crypto.stored)
                    ? true
                    : false
                }
              />
            }
            labelPlacement="start"
            label={
              <Grid
                container
                direction="row"
                alignItems="center"
                style={{ minWidth: "120px", height: "24px" }}
              >
                <Grid item xs={3} style={{height:'100%'}} container alignItems="center" alignContent="center">
                  {isStored && !props.crypto.changed && props.crypto.stored ? (
                    <LockedIcon />
                  ) : (
                    <LockOpenIcon />
                  )}
                </Grid>
                <Grid item xs={9} style={{height:'100%'}} container alignItems="center" alignContent="center">
                  {label}
                </Grid>
              </Grid>
            }
          />
        </div>
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    theme: state.theme,
    baseurl: state.baseurl,
    crypto: state.crypto,
    temp: state.temp,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setTheme: (theme) => {
    dispatch(setTheme(theme));
  },
  setCrypto: (crypto) => dispatch(setCrypto(crypto)),
  setTemp: (temp) => dispatch(setTemp(temp)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Credentials);
