import React from 'react'
import { connect } from 'react-redux'
import { setUser, setAlert, setTemp } from '../actions'

function Check(props) {
	return <React.Fragment></React.Fragment>
}

const mapStateToProps = (state) => ({
	user: state.user,
	temp: state.temp,
})

const mapDispatchToProps = (dispatch) => ({
	setUser: (user) => dispatch(setUser(user)),
	setAlert: (alert) => dispatch(setAlert(alert)),
	setTemp: (temp) => dispatch(setTemp(temp)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Check)