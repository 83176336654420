import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { setUser, setAlert, setTemp, setCrypto } from "../actions";
import { Button, TextField, Grid, Link } from "@material-ui/core";
//import Director from "../Director";
//import Redirector from "../Redirector";
import Crypto from "../Crypto";
import axios from "axios";

const useStyles = makeStyles(
  (theme) => ({
    lookup_form: {
      width: "100%",
      marginTop: theme.spacing(2),
    },
  }),
  { withTheme: true }
);

function Home(props) {
  const classes = useStyles();

  const [lookup, setLookup] = React.useState(props.crypto.lookupKey);

  var new_crypto = Object.assign({}, props.crypto);

  var setCrypto = props.setCrypto;

  React.useEffect(() => {
    if (new_crypto.refresh_lookup_key) {
      //setPassphrase(new_crypto.passphrase)
      setLookup(new_crypto.lookupKey);
      new_crypto.refresh_lookup_key = false;
      setCrypto(new_crypto);
    }
  }, [new_crypto, setCrypto, setLookup]);

  return (
    <form
      className={classes.lookup_form}
      onSubmit={async (e) => {
        e.preventDefault();

        var new_crypto = Object.assign({}, props.crypto);
        new_crypto.lookupKey = lookup;
        new_crypto.lookupKeyFound = "";
        new_crypto.lookupKeyFingerprint = "";
        new_crypto.lookupKeyNotFound = "";

        props.changeCrypto(async () => {
          var new_crypto = Object.assign({}, props.crypto);
          if (!new_crypto.lookupKey || !new_crypto.lookupKey.length) {
            props.setAlert({
              open: true,
              severity: "error",
              message: "you must provide a valid key",
              vertical: "bottom",
              horizontal: "right",
            });
            return false;
          }

          var extracted = await Crypto.extract_id(new_crypto.lookupKey);

          if (extracted) {
            var key_lookupKey = extracted;
          } else {
            key_lookupKey = new_crypto.lookupKey;
          }

          let found = await axios.post("/lookupkey", { key: key_lookupKey });
          console.log("test");
          if (found.data.key_id) {
            new_crypto.lookupKeyFound = found.data.key_id;
            new_crypto.lookupKeyFingerprint = found.data.key_fingerprint;
            new_crypto.lookupKeyNotFound = false;
            new_crypto.registry_entry = found.data.registry_entry;
          } else {
            new_crypto.lookupKeyNotFound = found.data.failed;
            new_crypto.lookupKeyFound = false;
            new_crypto.lookupKeyFingerprint = null;
          }

          props.setCrypto(new_crypto);
        }, new_crypto);

        //Redirector("/check/" + lookupKey);
        //          Redirector("https://"+process.env.REACT_APP_DOMAIN+"/key/" + key_lookupKey);
      }}
    >
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={12}>
          <TextField
            label="public key or public key id"
            multiline={true}
            minRows={5}
            maxRows={5}
            variant={process.env.REACT_APP_STYLE}
            fullWidth
            size="small"
            onChange={(e) => {
              var new_crypto = Object.assign({}, props.crypto);
              new_crypto.lookupKeyFound = "";
              new_crypto.lookupKeyFingerprint = "";
              new_crypto.lookupKeyNotFound = "";
              new_crypto.lookupKey = e.target.value;

              setLookup(e.target.value);
              if (window.typing) {
                clearTimeout(window.typing);
              }
              window.typing = setTimeout(() => {
                props.setCrypto(new_crypto);
              }, 100);
            }}
            value={lookup}
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            size="small"
            fullWidth
            color="primary"
          >
            Lookup Key
          </Button>
        </Grid>
        {}
        {props.crypto.lookupKeyFound ? (
          <Grid item xs={12} style={{ paddingBottom: "0px" }}>
            <div>
              <Grid>
                <Grid item xs={12}>
                  key registered:{" "}
                  <Link
                    href={
                      "https://" +
                      process.env.REACT_APP_DOMAIN +
                      "/key/" +
                      props.crypto.lookupKeyFound
                    }
                    target={"_" + props.crypto.lookupKeyFound}
                  >
                    {process.env.REACT_APP_DOMAIN}/key/
                    {props.crypto.lookupKeyFound}
                  </Link>
                  {props.crypto.lookupKeyFingerprint ? (
                    <React.Fragment>
                      <br />
                      fingerprint: {props.crypto.lookupKeyFingerprint}
                    </React.Fragment>
                  ) : null}
                </Grid>
              </Grid>
            </div>
          </Grid>
        ) : null}

        {props.crypto.lookupKeyNotFound ? (
          <Grid item xs={12} style={{ paddingBottom: "0px" }}>
            <div>
              <Grid>
                <Grid item xs={12}>
                  {props.crypto.lookupKeyNotFound === "invalid key" ? (
                    "invalid key not found"
                  ) : (
                    <React.Fragment>
                      key not registered:{" "}
                      <a
                        href={
                          "https://" +
                          process.env.REACT_APP_DOMAIN +
                          "/key/" +
                          props.crypto.lookupKeyNotFound
                        }
                        target={"_" + props.crypto.lookupKeyNotFound}
                      >
                        {process.env.REACT_APP_DOMAIN}/key/
                        {props.crypto.lookupKeyNotFound}
                      </a>
                    </React.Fragment>
                  )}
                </Grid>
              </Grid>
            </div>
          </Grid>
        ) : null}

        <Grid item xs={12} style={{ paddingTop: "0px" }}>
          {/*
					<div>
						{process.env.REACT_APP_BRAND} public key:{" "}
						<Link
							href={
								"https://" +
								process.env.REACT_APP_DOMAIN +
								"/key/" +
								process.env.REACT_APP_PUBLIC_KEY_ID
							}
							target={"_" + process.env.REACT_APP_BRAND + "_public_key"}
						>
							{process.env.REACT_APP_DOMAIN}/key/
							{process.env.REACT_APP_PUBLIC_KEY_ID}
						</Link>
						<br />
						{process.env.REACT_APP_BRAND} public key id:{" "}
						{process.env.REACT_APP_PUBLIC_KEY_ID}
						<br />
						fingerprint:{" "}
						{process.env.REACT_APP_PUBLIC_KEY_FINGERPRINT}
					</div>
						*/}

          <div style={{ marginTop: "16px" }}>
            {process.env.REACT_APP_BRAND} public key:{" "}
            <Link
              href={
                "https://" +
                process.env.REACT_APP_DOMAIN +
                "/key/" +
                process.env.REACT_APP_PUBLIC_KEY_ID
              }
              target={"_" + process.env.REACT_APP_BRAND + "_public_key"}
            >
              {process.env.REACT_APP_DOMAIN}/key/
              {process.env.REACT_APP_PUBLIC_KEY_ID}
            </Link>
            <br />
            {process.env.REACT_APP_BRAND} public key id:{" "}
            {process.env.REACT_APP_PUBLIC_KEY_ID}
            <br />
            fingerprint: {process.env.REACT_APP_PUBLIC_KEY_FINGERPRINT}
          </div>
        </Grid>
      </Grid>
    </form>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  crypto: state.crypto,
  temp: state.temp,
});

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(setUser(user)),
  setAlert: (alert) => dispatch(setAlert(alert)),
  setTemp: (temp) => dispatch(setTemp(temp)),
  setCrypto: (crypto) => dispatch(setCrypto(crypto)),
  changeCrypto: (callback, crypto) => {
    dispatch((dispatch) => {
      callback(dispatch(setCrypto(crypto)).crypto);
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
