import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { setUser, setAlert, setTemp, setCrypto } from "../actions";
import { Button, TextField, Grid, Typography } from "@material-ui/core";
import Crypto from "../Crypto";
//import Signature from "./Signature";
import Hash from "./Hash";
//import Moment from "moment-timezone"

const useStyles = makeStyles(
  (theme) => ({
    lookup_form: {
      width: "100%",
    },
    box: {
      marginTop: theme.spacing(3),
    },
    content: {
      marginTop: theme.spacing(1),
    },
    sub: {
      marginTop: theme.spacing(2),
    },
    button_container: {
      marginTop: theme.spacing(0.5),
    },
  }),
  { withTheme: true }
);

function Generate(props) {
  //console.log(props)

  const classes = useStyles();

  const [output, setOutput] = React.useState(props.crypto.output);
  const [sender, setSender] = React.useState(props.crypto.sender);

  //  const lookup = React.useRef(null);

  var new_crypto = Object.assign({}, props.crypto);

  var setCrypto = props.setCrypto;

  React.useEffect(() => {
    if (new_crypto.refresh_output) {
      //setPassphrase(new_crypto.passphrase)
      setSender(new_crypto.sender);
      setOutput(new_crypto.output);
      new_crypto.refresh_output = false;
      setCrypto(new_crypto);
    }
  }, [new_crypto, setCrypto, setOutput, setSender]);

  return (
    <React.Fragment>
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={12} md={6}>
          <TextField
            label="output"
            variant={process.env.REACT_APP_STYLE}
            fullWidth
            multiline
            minRows={5}
            maxRows={5}
            size="small"
            onChange={(e) => {
              var new_crypto = Object.assign({}, props.crypto);
              new_crypto.output = e.target.value;
              new_crypto.verified = null;
              new_crypto.decrypted = null;

              setOutput(e.target.value);
              if (window.typing) {
                clearTimeout(window.typing);
              }
              window.typing = setTimeout(() => {
                props.setCrypto(new_crypto);
              }, 250);
            }}
            value={output}
            type="text"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            label="sender"
            multiline={true}
            minRows={5}
            maxRows={5}
            variant={process.env.REACT_APP_STYLE}
            fullWidth
            size="small"
            onChange={(e) => {
              var new_crypto = Object.assign({}, props.crypto);
              new_crypto.sender = e.target.value;

              setSender(e.target.value);
              if (window.typing) {
                clearTimeout(window.typing);
              }
              window.typing = setTimeout(() => {
                props.setCrypto(new_crypto);
              }, 250);
            }}
            value={sender}
            type="text"
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} className={classes.button_container}>
        <Grid item xs={6}>
          <Button
            color="primary"
            type="submit"
            variant="contained"
            size="small"
            fullWidth
            onClick={async () => {
              var verified = await Crypto.verifyMessage(props.crypto.output);

              var new_crypto = Object.assign({}, props.crypto);

              new_crypto.verification = null;

              if (verified.signed_by) {
                new_crypto.verified = verified;
                props.setCrypto(new_crypto);
              } else {
                if (verified.error) {
                  new_crypto.verified = {};
                  new_crypto.verified.error = verified.error;
                }
                //new_crypto.public_key_id = "";
                new_crypto.decrypted = "";
                props.setCrypto(new_crypto);
                props.setAlert({
                  open: true,
                  severity: "error",
                  message: verified.error
                    ? verified.error
                    : "the signature is not valid",
                  vertical: "bottom",
                  horizontal: "center",
                });
              }
            }}
            disabled={
              !props.crypto.sender || !props.crypto.output ? true : false
            }
          >
            Verify
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            color="primary"
            type="submit"
            variant="contained"
            size="small"
            fullWidth
            onClick={async () => {
              var verification = await Crypto.verifySignature(
                props.crypto.input,
                props.crypto.output
              );
              var new_crypto = Object.assign({}, props.crypto);

              new_crypto.verification = verification;
              new_crypto.verified = null;
              props.setCrypto(new_crypto);

              if (new_crypto.verification && new_crypto.verification.original) {
              } else {
                props.setAlert({
                  open: true,
                  severity: "error",
                  message: verification.error
                    ? verification.error
                    : "the signature is not valid",
                  vertical: "bottom",
                  horizontal: "center",
                });
              }
            }}
            disabled={
              !props.crypto.sender ||
              !props.crypto.output ||
              !props.crypto.input
                ? true
                : false
            }
          >
            Verify Signature
          </Button>
        </Grid>

        <Grid item xs={6}>
          <Button
            color="primary"
            type="submit"
            variant="contained"
            size="small"
            fullWidth
            onClick={async () => {
              var decrypted = await Crypto.decryptPublic(props.crypto.output);
              var new_crypto = Object.assign({}, props.crypto);
              new_crypto.decrypted = decrypted.trim();
              new_crypto.verified = null;
              props.setCrypto(new_crypto);
            }}
            disabled={
              !props.crypto.private_key || !props.crypto.output ? true : false
            }
          >
            Decrypt
          </Button>
        </Grid>

        <Grid item xs={6}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="small"
            fullWidth
            onClick={async () => {
              var new_crypto = Object.assign({}, props.crypto);
              var decrypted_signed = await Crypto.decrypt(props.crypto.output);

              var verified = {};
              verified.message = decrypted_signed.data;
              verified.signed_by = decrypted_signed.signatures[0].keyid.toHex();

              new_crypto.decrypted = null;
              new_crypto.verified = verified;

              props.setCrypto(new_crypto);
            }}
            disabled={
              !props.crypto.private_key ||
              !props.crypto.sender ||
              !props.crypto.output
                ? true
                : false
            }
          >
            Decrypt & Verify
          </Button>
        </Grid>

        {props.crypto.decrypted ? (
          <Grid item xs={12}>
            <div>
              <Grid>
                <Grid item xs={12}>
                  <TextField
                    label="unarmored content"
                    variant="filled"
                    fullWidth
                    multiline
                    minRows={5}
                    maxRows={5}
                    size="small"
                    value={props.crypto.decrypted}
                    type="text"
                    disabled
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
        ) : null}

        {props.crypto.verified && !props.crypto.verified.error ? (
          <Grid item xs={12}>
            <TextField
              label="signed by public key"
              disabled
              variant="filled"
              fullWidth
              size="small"
              value={props.crypto.verified.signed_by}
              type="text"
            />
          </Grid>
        ) : null}

        {props.crypto.verified && !props.crypto.verified.error ? (
          <Grid item xs={12}>
            <TextField
              label="fingerprint of public key"
              disabled
              variant="filled"
              fullWidth
              size="small"
              value={props.crypto.verified.fingerprint}
              type="text"
            />
          </Grid>
        ) : null}

        {props.crypto.verified && !props.crypto.verified.error ? (
          <Grid item xs={12}>
            <TextField
              label="unarmored content"
              variant="filled"
              fullWidth
              multiline
              minRows={5}
              maxRows={5}
              size="small"
              value={props.crypto.verified.message}
              type="text"
              disabled
            />
          </Grid>
        ) : null}

        {props.crypto.verification && props.crypto.verification.original ? (
          <React.Fragment>
            <Grid item xs={12}>
              <TextField
                label="signed by public key"
                disabled
                variant="filled"
                fullWidth
                size="small"
                value={props.crypto.verification.signed_by}
                type="text"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="fingerprint of public key"
                disabled
                variant="filled"
                fullWidth
                size="small"
                value={props.crypto.verification.fingerprint}
                type="text"
              />
            </Grid>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {props.crypto.verification &&
            props.crypto.verification.signed_by ? (
              <Grid item xs={12}>
                <Typography color="error">invalid signature</Typography>
              </Grid>
            ) : props.crypto.verification && props.crypto.verification.error ? (
              <Grid item xs={12}>
                <Typography color="error">
                  {props.crypto.verification.error}
                </Typography>
              </Grid>
            ) : null}

            {props.crypto.verified && props.crypto.verified.error ? (
              <Grid item xs={12}>
                <Typography color="error">
                  {props.crypto.verified.error}
                </Typography>
              </Grid>
            ) : null}

            {props.crypto.verified === false ? (
              <Grid item xs={12}>
                <Typography color="error">invalid signature</Typography>
              </Grid>
            ) : null}
          </React.Fragment>
        )}
      </Grid>

      {/*
      <Grid item xs={12}>
        <div className={classes.sub}>
          <Signature />
        </div>
      </Grid>
*/}
      <Grid item xs={12}>
        <div className={classes.sub}>
          <Hash public={props.public} />
        </div>
      </Grid>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  temp: state.temp,
  crypto: state.crypto,
});

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(setUser(user)),
  setAlert: (alert) => dispatch(setAlert(alert)),
  setTemp: (temp) => dispatch(setTemp(temp)),
  setCrypto: (crypto) => dispatch(setCrypto(crypto)),
  changeCrypto: (callback, crypto) => {
    dispatch((dispatch) => {
      callback(dispatch(setCrypto(crypto)).crypto);
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Generate);
