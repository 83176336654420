import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { setUser, setAlert, setTemp, setCrypto } from "../actions";
import { Button, TextField, Grid, Box } from "@material-ui/core";
import axios from "axios";
import Crypto from "../Crypto";
import ReplayIcon from "@material-ui/icons/Replay";
import Moment from "moment-timezone";

const public_key_id = process.env.REACT_APP_PUBLIC_KEY_ID;

const useStyles = makeStyles(
  (theme) => ({
    submit_form: {
      width: "100%",
      marginTop: theme.spacing(1.5),
    },
    form_container: {
      marginTop: theme.spacing(0.5),
    },
    input: {
      margin: "0px",
      padding: "0px !important",
    },
    file_box: {
      marginTop: theme.spacing(1),
    },
  }),
  { withTheme: true }
);

function File(props) {
  const [submitMark, setSubmitMark] = React.useState("");
  //const [signedMark, setSignedMark] = React.useState("");
  //  const [markID, setMarkID] = React.useState("");
  //  const [mark, setMark] = React.useState("");
  const [file, setFile] = React.useState("");
  const [files, setFiles] = React.useState("");

  //const [saving, setSaving] = React.useState(false)

  const [fileSize, setFileSize] = React.useState(null);

  const [disableSubmit, setDisableSubmit] = React.useState(false);

  const classes = useStyles();

  const submit = React.useRef(null);
  const upload_file = React.useRef(null);

  var finalSubmit = async (e, formData) => {
    e.preventDefault();

    setSubmitMark(submit.current.value);

    if (!submitMark || !submitMark.length) {
      props.setAlert({
        open: true,
        severity: "error",
        message: "message cannot be blank",
        vertical: "bottom",
        horizontal: "right",
      });
      return false;
    }

    var ro = {};
    ro.method = "POST";
    ro.url = "/generate_token";
    ro.data = { file: true };
    ro.data["public_key"] = props.crypto.public_key;

    var setup = await axios(ro);

    var extract_token_timestamp = await Crypto.verifyServerMessage(
      setup.data.token,
      "verify"
    ).then(async (result) => {
      return result;
    });

    if (extract_token_timestamp.signed_by === public_key_id) {
      var token_timestamp = extract_token_timestamp.message.split(":::")[1];
    }

    var armored = await Crypto.signMessage(
      process.env.REACT_APP_BRAND +
        ":" +
        setup.data.hashed_token +
        ":::" +
        token_timestamp +
        "\r\n" +
        submitMark,
      false,
      { creation_time: formData.creation_time }
    ).catch((err) => {
      props.setAlert({
        open: true,
        severity: "error",
        message: err,
        vertical: "bottom",
        horizontal: "center",
      });
      return false;
    });

    if (!armored) {
      return false;
    }

    var armored_only = await Crypto.signMessage(submitMark, false, {
      creation_time: formData.creation_time,
    });

    Crypto.verifyMessage(armored, "verify", {
      creation_time: formData.creation_time,
    })
      .then(async (result) => {
        props.setAlert({
          open: true,
          severity: "success",
          message: "message is properly formatted",
          vertical: "bottom",
          horizontal: "right",
        });

        ro = {};
        ro.method = "POST";
        ro.url = "/submit_mark";
        ro.data = formData;

        formData.append("verify_mark", armored);
        formData.append("mark", armored_only);
        formData.append("token", setup.data.token);
        formData.append("hashed_token", setup.data.hashed_token);
        formData.append("verify_mark", armored);
        formData.append("public_key", props.crypto.public_key);
        formData.append("creation_time", formData.creation_time);

        ro.headers = {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        };

        var marked = await axios(ro);

        //setDisableSubmit(false)

        //setSignedMark(marked.data.html);

        var new_crypto = Object.assign({}, props.crypto);
        new_crypto.signedMark = marked.data.html;
        new_crypto.qr = marked.data.qr;
        new_crypto.mark_id = marked.data.mark_id;
        new_crypto.registeredKey = null;

        new_crypto.refresh_id = true;
        new_crypto.lookupMark = marked.data.mark_id;
        new_crypto.foundMark = marked.data.mark_id;
        props.setCrypto(new_crypto);

        //setMarkID(marked.data.mark_id)
        //setMark(marked.data.mark)

        setSubmitMark("");
        setFile("");
        setFileSize("");
      })
      .catch((err) => {
        //setDisableSubmit(false)
        props.setAlert({
          open: true,
          severity: "error",
          message: "message is not properly formatted",
          vertical: "bottom",
          horizontal: "right",
        });
      });
  };

  return (
    <form
      className={classes.submit_form}
      onSubmit={async (e) => {
        e.preventDefault();

        if (disableSubmit) {
          return false;
        }

        setDisableSubmit(true);

        var formData = new FormData();

        if (file && fileSize) {
          Crypto.hash(file.toString());
          Crypto.hash(fileSize.toString());
          formData.append("file", files[0]);
        }

        //return false

        formData.creation_time = Moment().milliseconds(0).valueOf();
        //formData.creation_time = '2020-12-01T06:00:00.000Z'

        finalSubmit(e, formData);
      }}
    >
      <Grid
        container
        alignItems="center"
        spacing={1}
        className={classes.form_container}
      >
        <Grid item xs={12}>
          <TextField
            label="content"
            variant={process.env.REACT_APP_STYLE}
            multiline={true}
            minRows={10}
            maxRows={10}
            fullWidth
            size="small"
            onChange={(e) => {
              var new_crypto = Object.assign({}, props.crypto);
              new_crypto.mark_id = null;
              new_crypto.signedMark = null;
              new_crypto.registeredKey = null;
              new_crypto.qr = null;
              setDisableSubmit(false);
              setSubmitMark(e.target.value);

              if (window.typing) {
                clearTimeout(window.typing);
              }

              window.typing = setTimeout(() => {
                props.setCrypto(new_crypto);
              }, 100);

              //setTimeout(()=>{props.setCrypto(new_crypto)},0)
            }}
            value={submitMark}
            inputRef={submit}
          />
        </Grid>

        <Grid item xs={12}>
          <Box pt={1}>
            {!file ? (
              <React.Fragment>
                <TextField
                  fullWidth
                  inputProps={{
                    multiple: false,
                    style: {
                      padding: "8px",
                      paddingBottom: "15px",
                      paddingLeft: "auto",
                      paddingRight: "auto",
                      height: "17px",
                    },
                  }}
                  margin="none"
                  type="file"
                  onChange={(e) => {
                    var target = e.target;

                    if (target && target.files) {
                      const files = Array.from(target.files);

                      //const formData = new FormData()

                      files.forEach((file, i) => {
                        setFile(file.name);
                        setFileSize(file.size);
                        setFiles(files);
                      });

                      return false;

                      //finalSubmit(e,formData)

                      /*
												axios({
													method: 'POST',
													url: '/submit_mark',
													data: formData,
													headers: {
														Accept: 'application/json',
														'Content-Type': 'multipart/form-data',
													},
												})
													.then((res) => {
														if (res.data.files && res.data.files.length) {
															setFile(res.data.files[0])
														}
													})
													.catch(function (thrown) {
														console.log(thrown)
                          })
                          */
                    }

                    //this changes the value of the data that was passed down to state from props so that the text field updates, without updating state text field value will always come from state.data that was sent from (but now isolated from) props, so changing the text will do nothing unless this changes the state that provides the value to the field. This by itself does not trigger a save, the blur handler must happen to trigger a save or a save must be triggered. This is not needed at all if the async solution is in use, as it will update and save. Use this instead of the this.props.handleChange line  if you want to update the state locally and reflect changes, but trigger the save manually.
                    //this.handleChange('data', obj);
                  }}
                  value={file}
                  variant={process.env.REACT_APP_STYLE}
                  color="primary"
                  multiline={false}
                  inputRef={upload_file}
                />

                <div className={classes.file_box}>
                  <div style={{ marginBotom: "8px", marginTop: "4px" }}>
                    attach a file (optional)
                  </div>

                  <div style={{ marginBotom: "4px", marginTop: "8px" }}>
                    files must be no no larger than 1024 KB
                  </div>
                </div>
              </React.Fragment>
            ) : file ? (
              <React.Fragment>
                <Button
                  color="primary"
                  fullWidth
                  style={{ height: "40px" }}
                  variant={process.env.REACT_APP_STYLE}
                  aria-label="Clear"
                  onClick={() => {
                    setFile("");
                    setFileSize("");
                  }}
                  startIcon={<ReplayIcon />}
                >
                  Remove File
                </Button>
              </React.Fragment>
            ) : null}

            {file ? (
              <div className={classes.file_box}>
                <div style={{ marginBotom: "8px", marginTop: "4px" }}>
                  attached file: {file.substring(0, 55)}
                </div>
                <div style={{ marginBotom: "4px", marginTop: "8px" }}>
                  file size: {(fileSize / 1024).toFixed(2)} KB
                </div>
              </div>
            ) : null}
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="small"
            fullWidth
            disabled={disableSubmit || !props.crypto.private_key ? true : false}
          >
            Submit Mark
          </Button>
        </Grid>

        {props.crypto.mark_id ? (
          <Grid item xs={12}>
            <TextField
              label={process.env.REACT_APP_BRAND + " id"}
              disabled
              variant="filled"
              fullWidth
              size="small"
              value={props.crypto.mark_id}
              type="text"
            />
            <div style={{ fontFamily: "monospace", marginTop: "8px" }}>
              public:{" "}
              <a
                href={
                  "https://" +
                  process.env.REACT_APP_DOMAIN +
                  "/pub/" +
                  props.crypto.mark_id
                }
                target={"_" + props.crypto.mark_id}
              >
                {process.env.REACT_APP_DOMAIN + "/pub/" + props.crypto.mark_id}
              </a>
            </div>
            <div style={{ fontFamily: "monospace" }}>
              signed:{" "}
              <a
                href={
                  "https://" +
                  process.env.REACT_APP_DOMAIN +
                  "/sig/" +
                  props.crypto.mark_id
                }
                target={"_" + props.crypto.mark_id}
              >
                {process.env.REACT_APP_DOMAIN + "/sig/" + props.crypto.mark_id}
              </a>
            </div>
            <div style={{ fontFamily: "monospace" }}>
              static:{" "}
              <a
                href={
                  "https://" +
                  process.env.REACT_APP_DOMAIN +
                  "/txt/" +
                  props.crypto.mark_id
                }
                target={"_" + props.crypto.mark_id}
              >
                {process.env.REACT_APP_DOMAIN + "/txt/" + props.crypto.mark_id}
              </a>
            </div>
            <div style={{ fontFamily: "monospace" }}>
              export:{" "}
              <a
                href={
                  "https://" +
                  process.env.REACT_APP_DOMAIN +
                  "/raw/" +
                  props.crypto.mark_id
                }
                target={"_" + props.crypto.mark_id}
              >
                {process.env.REACT_APP_DOMAIN + "/raw/" + props.crypto.mark_id}
              </a>
            </div>
            <div style={{ fontFamily: "monospace" }}>
              output:{" "}
              <a
                href={
                  "https://" +
                  process.env.REACT_APP_DOMAIN +
                  "/out/" +
                  props.crypto.mark_id
                }
                target={"_" + props.crypto.mark_id}
              >
                {process.env.REACT_APP_DOMAIN + "/out/" + props.crypto.mark_id}
              </a>
            </div>
            <div style={{ fontFamily: "monospace" }}>
              verify:{" "}
              <a
                href={
                  "https://" +
                  process.env.REACT_APP_DOMAIN +
                  "/mid/" +
                  props.crypto.mark_id
                }
                target={"_" + props.crypto.mark_id}
              >
                {process.env.REACT_APP_DOMAIN + "/mid/" + props.crypto.mark_id}
              </a>
            </div>
            <div style={{ fontFamily: "monospace" }}>
              format:{" "}
              <a
                href={
                  "https://" +
                  process.env.REACT_APP_DOMAIN +
                  "/css/" +
                  props.crypto.mark_id
                }
                target={"_" + props.crypto.mark_id}
              >
                {process.env.REACT_APP_DOMAIN + "/css/" + props.crypto.mark_id}
              </a>
            </div>
          </Grid>
        ) : null}
      </Grid>
    </form>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  temp: state.temp,
  crypto: state.crypto,
});

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(setUser(user)),
  setAlert: (alert) => dispatch(setAlert(alert)),
  setTemp: (temp) => dispatch(setTemp(temp)),
  setCrypto: (crypto) => dispatch(setCrypto(crypto)),
  changeCrypto: (callback, crypto) => {
    dispatch((dispatch) => {
      callback(dispatch(setCrypto(crypto)).crypto);
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(File);
