import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { setUser, setAlert, setTemp, setCrypto } from "../actions";
import { Button, TextField, Grid } from "@material-ui/core";
import Crypto from "../Crypto";
import Bits from "./Bits";
//import store from "store"

const useStyles = makeStyles(
  (theme) => ({
    lookup_form: {
      width: "100%",
      marginTop: theme.spacing(0.5),
    },
  }),
  { withTheme: true }
);

function Generate(props) {
  var new_crypto = Object.assign({}, props.crypto);

  var setCrypto = props.setCrypto;

  const [passphrase, setPassphrase] = React.useState(props.crypto.passphrase);
  const [name, setName] = React.useState(props.crypto.name);
  const [email, setEmail] = React.useState(props.crypto.email);

  React.useEffect(() => {
    if (new_crypto.refresh_creds) {
      if (new_crypto.refresh_passphrase) {
        setPassphrase(new_crypto.passphrase);
        new_crypto.refresh_passphrase = false;
      }

      if (new_crypto.refresh_passphrase === '') {
        setPassphrase(new_crypto.passphrase);
        new_crypto.refresh_passphrase = false;
      }

      setName(new_crypto.name);
      setEmail(new_crypto.email);
      new_crypto.refresh_creds = false;
      setCrypto(new_crypto);
    }
  }, [new_crypto, setCrypto, setEmail, setName, setPassphrase]);

  const classes = useStyles();

  return (
    <form
      className={classes.lookup_form}
      onSubmit={async (e) => {
        e.preventDefault();
        

        var temp = Object.assign({}, props.temp, {
          confirm: {
            title: "Generating keys",
            text: `Do not close or reload this page`,
            hide_buttons: true,
            prevent_close: true
          },
        });

        props.setTemp(temp);

        let crypto = await Crypto.generate(props.crypto.bits);
        var new_crypto = Object.assign({}, crypto);
        new_crypto.refresh_keys = true;
        new_crypto.refresh_entry = true;
        props.changeCrypto(async () => {
          await Crypto.extract();

          var new_temp = Object.assign({}, props.temp);
          new_temp.refresh_key = true;
          new_temp.confirm = null;
          props.setTemp(new_temp);
        }, new_crypto);
      }}
    >
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={12}>
          <TextField
            variant={process.env.REACT_APP_STYLE}
            fullWidth
            size="small"
            onChange={(e) => {
              var new_crypto = Object.assign({}, props.crypto);
              new_crypto.passphrase = e.target.value;
              //new_crypto.stored = false
              //new_crypto.changed = true
              //store.remove('crypto')

              setPassphrase(e.target.value);
              if (window.typing) {
                clearTimeout(window.typing);
              }
              window.typing = setTimeout(() => {
                props.setCrypto(new_crypto);
              }, 250);
            }}
            value={passphrase}
            label="passphrase"
            type="password"
          />
        </Grid>

        {!props.verify ? (
          <React.Fragment>
            <Grid item xs={12}>
              <TextField
                variant={process.env.REACT_APP_STYLE}
                fullWidth
                size="small"
                onChange={(e) => {
                  var new_crypto = Object.assign({}, props.crypto);
                  new_crypto.name = e.target.value;
                  new_crypto.stored = false;
                  new_crypto.changed = true;

                  setName(e.target.value);
                  if (window.typing) {
                    clearTimeout(window.typing);
                  }
                  window.typing = setTimeout(() => {
                    //store.remove('crypto')
                    props.setCrypto(new_crypto);
                  }, 250);
                }}
                value={name}
                label="name"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant={process.env.REACT_APP_STYLE}
                fullWidth
                size="small"
                onChange={(e) => {
                  var new_crypto = Object.assign({}, props.crypto);
                  new_crypto.email = e.target.value;
                  new_crypto.stored = false;
                  new_crypto.changed = true;

                  setEmail(e.target.value);
                  if (window.typing) {
                    clearTimeout(window.typing);
                  }
                  window.typing = setTimeout(() => {
                    //store.remove('crypto')
                    props.setCrypto(new_crypto);
                  }, 250);
                }}
                value={email}
                label="email"
              />
            </Grid>

            <Grid item xs={12}>
              <Bits />
            </Grid>
          </React.Fragment>
        ) : null}

        {!props.verify ? (
          <Grid item xs={12}>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                size="small"
                fullWidth
                color="primary"
              >
                Generate Keys
              </Button>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </form>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  temp: state.temp,
  crypto: state.crypto,
});

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(setUser(user)),
  setAlert: (alert) => dispatch(setAlert(alert)),
  setTemp: (temp) => dispatch(setTemp(temp)),
  setCrypto: (crypto) => dispatch(setCrypto(crypto)),
  changeCrypto: (callback, crypto) => {
    dispatch((dispatch) => {
      callback(dispatch(setCrypto(crypto)).crypto);
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Generate);
