import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
//import Switch from '@material-ui/core/Switch'
import { connect } from 'react-redux'
import { setTheme } from './actions'
//import FormControlLabel from '@material-ui/core/FormControlLabel'

//import Checkbox from '@material-ui/core/Checkbox';
import { Button } from '@material-ui/core';
import Night from '@material-ui/icons/Brightness2TwoTone';
import Day from '@material-ui/icons/WbSunnyTwoTone';

var store = require("store");

const useStyles = makeStyles({
	switch: {
        marginRight: '0px',
        marginLeft: '0px'
    },
    switch_container: {
        margin: '0px',
        marginTop: '0px',
        '& *': {
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif !important',
        },
        '& span': {
            fontFamily: 'monospace !important',
            
        },        
    },
    mode: {
        label: {
            fontFamily: 'monospace !important',
            
        }
    }
})

function Theme(props) {

	const classes = useStyles()

	const toggle = () => {

		if (props.theme === 'light') {
			props.setTheme('dark')
		} else {
			props.setTheme('light')
		}


		if (store.get('token')) {
			
		}
	}

	return (
		<div>

<Button className={classes.mode} size="medium" margin="dense" onClick={toggle} startIcon={props.theme === 'dark' ? <Day /> : <Night />}>{props.theme === 'dark' ? 'turn on bright mode' : 'turn on night mode'}</Button>



            {/*
            <FormControlLabel
                className={classes.switch_container}
				control={
					<Switch
					color="primary"
						checked={props.theme === 'dark' ? true : false}
						onChange={toggle}
						value="checked"
						inputProps={{ 'aria-label': 'toggle dark mode' }}
						className={classes.switch}									
					/>
				}
				labelPlacement="start"				
				label={'lights off'}
			/>
            */}
		</div>
	)
}

const mapStateToProps = state => {
	return {
		theme: state.theme,
		baseurl: state.baseurl
	}
}

const mapDispatchToProps = dispatch => ({
	setTheme: theme => {
		dispatch(setTheme(theme))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Theme)
