import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { connect } from "react-redux";
import { setAlert } from "../actions";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: "100%",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: "100%",
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    faq: {
      margin: "0px !important",
      border: "1px solid rgba(0, 0, 0, .125)",
      marginBottom: "0px",
      boxShadow: "none",
      "&:not(:first-child)": {
        borderTop: 0,
      },
      "&:before": {
        display: "none",
      },
      "&.Mui-expanded": {
        "&:not(:first-child)": {
          borderTop: "0px solid rgba(0, 0, 0, .125)",
        },
      },
    },
    between: {
      marginTop: "8px !important",
      marginBottom: "0px !important",
      paddingBottom: "8px",
      borderLeft: "0px",
      borderRight: "0px",
      borderBottom: "1px solid rgba(0, 0, 0, .125)",
    },
    summary: {
      minHeight: "48px !important",
    },
    content: {},
    divider: {
      borderBottom: "1px solid rgba(0, 0, 0, .125)",
    },
  }),
  { withTheme: true }
);

const faqs = [];
faqs.push(
  {
    title: `Generate a key pair`,
    body: (
      <React.Fragment>
        1.) Enter a passphrase for the new key pair you will generate.
        <br />
        <br />
        2.) (optional) Enter a name. (e.g. John Smith or Jane Doe)
        <br />
        <br />
        3.) (optional) Enter the email address you want to associate with the
        key.
        <br />
        <br />
        4.) Click "Generate Keys".
        <br />
        <br />
        5.) The public key and private key fields will be populated with your
        new keys.
        <br />
        <br />
        6.) Save your private and public keys somewhere so that you can use them
        whenever you need to.
        <br />
        <br />
        7.) You can now use the other functions such as Sign, Encrypt, Verify,
        etc because the public key and private key fields now contain the valid
        keys that were just generated.
        <br />
        <br />
        8.) You can use the other functions immediately or come back to this
        interface at any time and populate the empty public key and private key
        fields with the same keys you saved in step 5 to use those keys when
        performing the other functions.
        <br />
        <br />
        9.) You can toggle the "store keys" switch to store your keys in the
        browser for later use. This is useful if you do not want to copy your
        keys into the fields each time you want to use them. You can remove your
        keys from storage at any time.
        <br />
        <br />
        10.) You can generate as many key pairs as you like and use different
        key pairs to sign, verify, encrypt and decrypt different messages.
      </React.Fragment>
    ),
  },
  {
    title: `Sign a message`,
    body: (
      <React.Fragment>
        1.) Ensure that private key field contains a valid private key. This
        value will be automatically populated for you after you generate a new
        key pair, but if you want to use an existing key you have previously
        generated just populate the private key field manually with the private
        key you previously saved.
        <br />
        <br />
        2.) Ensure that the passphrase box contains the passphrase associated
        with the private key you are using. This is the passphrase you chose
        when generating the key pair.
        <br />
        <br />
        3.) Enter a message into the input field labeled "input".
        <br />
        <br />
        4.) (optional) Enter the public key of the intended recipient of the
        signed message into the field labeled "recipient" and click "Add
        Recipient ID" to add a note containing the hashed value of the
        recipient's public key to the end of your your input. The note will read
        "SHA-256 hash of public key of original intended recipient of this
        signed message: " followed by the hashed value of the intended
        recipient's public key. The alternative way to accompish this is to
        encrypt the message first by following the "Encrypt a message"
        instructions and then sign that encrypted message before sending it.
        Encrypting the message will create a message that can only be opened by
        the intended recipient. Then signing the encrypted message will attach
        your signature to the message to prove that it was actually signed by
        you. When the recipient receives your message they can first validate
        the signature to verify that it came from you. After validating the
        signature the original message that was encrypted will be displayed
        without the signature so that the recipient can decrypt and read it.
        <br />
        <br />
        5.) Click "Sign"
        <br />
        <br />
        6.) The value of the output field will be populated with the value of
        the input field signed by your private key so that others can verify the
        authenticity of the message using your public key to ensure that it was
        actually signed using your private key. The output will not be encrypted
        (unless, of course, the input value provided was itself already
        encrypted, in which case the contents of the message will simply be the
        original encrypted input with your signature attached).
        <br />
        <br />
        7.) (optional - Encrypt the message you just signed) Make sure that the
        recipient field contains the public key of the intended recipient that
        you want to allow to decrypt the message and click "Encrypt" to replace
        the value of the output field with the encrypted version of the message
        you just signed. This new encrypted message will display the signed
        message when it is decrypted. That signed message can then be verified
        using your public key to ensure that it was actually signed using your
        private key.
      </React.Fragment>
    ),
  },
  {
    title: `Verify a message`,
    body: (
      <React.Fragment>
        1.) Populate the "sender" field with the public key belonging to the
        person claiming to have signed the message using their private key.
        <br />
        <br />
        2.) Populate the "output" field with the signed message
        <br />
        <br />
        3.) Click "Verify"
        <br />
        <br />
        4.) If the message was signed by the private key associated with the
        public key you entered then the verification details will be displayed
        informing you that the message was signed by the public key you entered
        and displaying the key's id number followed by the original message that
        was signed with the signature removed. If the original message was
        unencrypted before it was signed then the original contents of the
        message will be displayed without the signature. If the original message
        was encrypted before it was signed then the encrypted version of the
        original message will be displayed without the signature so that you can
        decrypt it by following the "Decrypt a message" instructions. If the
        message was not signed by the key in the "sender" field then you will
        receive an alert notifying you that "the signature is not valid".
      </React.Fragment>
    ),
  },
  {
    title: `Encrypt a message`,
    body: (
      <React.Fragment>
        1.) Ensure that the recipient field contains the public key belonging to
        the intended recipient so that they can decrypt the message.
        <br />
        <br />
        2.) Enter the message you want to encrypt in the "input" field.
        <br />
        <br />
        3.) Click "Encrypt"
        <br />
        <br />
        4.) The value of the output box will be populated with the encrypted
        value of the input field.
        <br />
        <br />
        5.) You can encrypt either an unsigned message or a message you've
        signed with your private key, both types of messages will be encrypted
        using the recipient's public key. The recipient can decrypt the message
        because it was encrypted with their public key. After decrypting the
        message the recipient can view its contents. If the original unencrypted
        message was unsigned then the decrypted message will show only the
        original message, but if the message was signed before it was encrypted
        then it will display the signed message containing both the original
        message and the signature. The recipient can then validate the signed
        message against your public key to ensure that the original message was
        actually signed by your private key.
        <br />
        <br />
        6.) (optional) After encrypting a message you can follow the "Sign a
        message" instructions to sign the message you have encrypted.
      </React.Fragment>
    ),
  },
  {
    title: `Decrypt a message`,
    body: (
      <React.Fragment>
        1.) Ensure that the private key field contains your private key. This
        value will be automatically populated for you after you generate a new
        key pair, but if you want to use an existing key pair you have
        previously generated just populate the private key field manually with
        the private key you previously saved.
        <br />
        <br />
        2.) Ensure that the passphrase box contains the passphrase associated
        with the private key you are using. This is the passphrase you chose
        when generating the key pair.
        <br />
        <br />
        3.) Enter the message you wanted to decrypt into the "output" field.
        <br />
        <br />
        4.) Click "Decrypt"
        <br />
        <br />
        5.) The value of the output box will be populated with the decrypted
        version of the message.
        <br />
        <br />
        6.) After decrypting the message you can view its contents. If the
        original unencrypted message was unsigned then the decrypted message
        will show only the original message, but if the message was signed
        before it was encrypted then it will display the signed message
        containing both the original message and the signature. You can then
        validate the signed message against the sender's public key to ensure
        that the original message was actually signed by their private key by
        following the "Verify a message" instructions.
      </React.Fragment>
    ),
  },
  {
    title: `Encrypt + Sign a message into a single value`,
    body: (
      <React.Fragment>
        1.) Ensure that the private key field contains your private key. This
        value will be automatically populated for you after you generate a new
        key pair, but if you want to use an existing key pair you have
        previously generated just populate the private key field manually with
        the private key you previously saved.
        <br />
        <br />
        2.) Ensure that the passphrase box contains the passphrase associated
        with the private key you are using. This is the passphrase you chose
        when generating the key pair.
        <br />
        <br />
        3.) Ensure that the recipient field contains the public key belonging to
        the intended recipient so that they can decrypt the message.
        <br />
        <br />
        4.) Enter the message you want to encrypt in the "input" field.
        <br />
        <br />
        5.) Click "Encrypt + Sign"
        <br />
        <br />
        6.) The value of the output box will be populated with an encrypted
        message that combines the content of the original input with your
        signature into a single encrypted value that can be sent to the
        recipient for decryption and verification. This message will be
        encrypted using the recipient's public key so that only they can read it
        and signed using your private key so that the recipient can verify its
        authenticity using your public key. The encrypted message will display
        your original input when it is decrypted by the recipient.
      </React.Fragment>
    ),
  },
  {
    title: `Decrypt & Verify a message`,
    body: (
      <React.Fragment>
        1.) Ensure that the output box contains an encrypted message that was
        both encrypted and signed into a single value (such as one created using
        the "Encrypt + Sign a message into a single value" instructions).
        <br />
        <br />
        2.) Ensure the "sender" field contains the public key of the person
        claiming to have created the message.
        <br />
        <br />
        3.) Ensure the "private key" field contains your private key.
        <br />
        <br />
        4.) Ensure the "passphrase" field contains the passphrase associated
        with your private key.
        <br />
        <br />
        5.) Click "Decrypt & Verify"
        <br />
        <br />
        6.) If the message can be decrypted using your private key and the
        public key of the attached signature matches the public key in the
        "sender" field then the verification details will be displayed informing
        you that the message was signed by the public key you entered as the
        sender and displaying the key's id number followed by the original
        "unarmored content" of the message that was originally signed, but with
        the signature removed. If the message cannot be decrypted using your
        private key or the message was not signed by the key in the "sender"
        field then you will receive an alert notifying you that "the message is
        not valid".
      </React.Fragment>
    ),
  },
  {
    title: `Create or verify a hash`,
    body: (
      <React.Fragment>
        1.) To create (or verify, depending on your use case) the SHA-256 OR MD5
        hash of a public key (or any other value) for verification you will
        populate the field labeled "hash" with the public key or other value
        that you want to hash and click "Create SHA-256" or "Create MD5" to
        replace the value in the "hash" field with its newly hashed value from
        the method you chose (SHA-256 or MD5). This value can be then compared
        to the hash of the intended recipient's public key that is included in a
        note at the bottom of a signed message (or compared to whatever other
        value you are wanting to verify it against). If you create a hash of
        your own public key and compare it to the hash of the intended
        recipient's public key included at the bottom of a signed message that a
        sender claims is intended for you then you will know if the message was
        actually intended for you if the two hash values match. If a signed
        message includes the hash of the intended recipient's public key in a
        note at the bottom then the recipient can create a hash of their own
        public key and compare it to the value in the note at the end of the
        message that was signed and sent them to confirm that the message
        contains a hash for the intended recipient's public key that matches
        their own public key's hash, proving that the message was originally
        intended for them by the person who originally signed it. This prevents
        other people from taking a signed message intended for Person A and
        encrypting it with Person B's public key to trick Person B into
        believing the message was intended for them since their public key is
        required to decrypt it, even though the sender only encrypted a message
        that was actually inteded for Person A with Person B's public key to
        disguise it as a message intended for Person B. Including the hash of
        the intended recipient's public key inside of the signed message itself
        lets the recipient verify that the message was originally intended for
        them by the person who originally signed it by comparing the hash of
        their own public key to the hash of the intended recipient's public key
        included in the signed message to see if they match.
      </React.Fragment>
    ),
  },
  {
    title: (

        
          <strong>Additional Instructions</strong>
        

    ),
    align: 'center'
  },
  {
    title: `Perform multiple actions in one step`,
    body: (
      <React.Fragment>
        1.) "Sign >> Encrypt" and "Encrypt >> Sign" will simply perform the
        functions from the "Sign a message" and "Encrypt a message" instructions
        in their respective orders according to the option you choose as long as
        the fields required for both steps contain the proper values.
      </React.Fragment>
    ),
  },
  {
    title: `Add a hashed value to the end of your input`,
    body: (
      <React.Fragment>
        1.) "Insert Hash" will add a note to the end of your input containing
        the current value of the "hash" field. If the value has not been hashed
        then the note will read "attached hash value: " followed by the value of
        the "hash" field. If the value has been hashed then depending on the
        type of hash you chose to create the note will read either "SHA-256
        attached hash value: " or "MD5 attached hash value: " followed by the
        hashed value in the "hash" field.
      </React.Fragment>
    ),
  },
  {
    title: `Create a detached signature`,
    body: (
      <React.Fragment>
        1.) The "Sign Detached" button will create a signature from the value of
        the "input" field using your private key just like when following the
        "Sign a message" instructions, but the created signature will not
        contain the message itself, only a signature which can be verified by
        following the "Verify a signature" instructions.
      </React.Fragment>
    ),
  },
  {
    title: `Verify a signature`,
    body: (
      <React.Fragment>
        1.) Ensure that the "sender" field contains the public key of the person
        claiming to have created the message.
        <br />
        <br />
        2.) Ensure that the "output" field contains a detached signature (such
        as one created using the "Create a detached signature" instructions)
        <br />
        <br />
        3.) Ensure that the "input" field contains the original input that the
        sender claims the signature is detached from.
        <br />
        <br />
        4.) Click "Verify Signature"
        <br />
        <br />
        5.) If the signature was signed by the private key associated with the
        public key you entered in the "sender" field and the input matches the
        original message that the signature was detached from then the
        verification details will be displayed informing you that the message
        was signed by the public key you entered as the sender and displaying
        the key's id number. If the signature was not signed by the private key
        associated with the public key entered in the "sender" field or the
        signature does not validate as having been detached from the provided
        "input" field value then a message will be displayed reading "invalid
        signature".
      </React.Fragment>
    ),
  }
);

function FAQ(props) {
  const [expanded, setExpanded] = React.useState({});

  const handleChange = (faq) => (event, isExpanded) => {
    var new_exp = {};

    if (expanded[faq]) {
      new_exp[faq] = false;
    } else {
      new_exp[faq] = true;
    }

    var final_exp = {};

    //comment out to allow multiple accordions to be open simultaneously
    final_exp = Object.assign({}, new_exp);

    final_exp = new_exp;

    console.log(final_exp);

    setExpanded(final_exp);
  };

  var classes = useStyles();

  var prev = "content";

  return (
    <div className={classes.root}>
      {faqs.map((f, i) => {
        if (f.body) {
          prev = "content";
          return (
            <Accordion
              expanded={expanded["faq" + i] ? expanded["faq" + i] : false}
              onChange={handleChange("faq" + i)}
              key={"faq" + i}
              TransitionProps={{
                timeout: 0,
              }}
              className={classes.faq + " faq subSection"}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={"faq" + i + "bh-content"}
                id={"faq" + i + "bh-header"}
                className={classes.summary}
              >
                <Typography
                  className={classes.heading + " bold"}
                  variant="body2"
                >
                  {f.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant={"body2"} component="div">
                  {f.body}
                </Typography>
              </AccordionDetails>
            </Accordion>
          );
        } else {
          prev = "divider";
          return (
            <div
              key={"faq" + i}
              className={
                "faq " + classes.between + " " + classes[prev] + " " + prev
              }
            >
              <Typography component={"p"} variant="body2" style={{textAlign: f.align ? f.align : 'left'}}>
                {f.title}
              </Typography>
            </div>
          );
        }
      })}
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setAlert: (alert) => dispatch(setAlert(alert)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FAQ);
