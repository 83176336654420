import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { connect } from "react-redux";
import { setAlert } from "../actions";
import ToolsHelp from "./ToolsHelp";
//import AboutHelp from "./AboutHelp";
//import RegistryHelp from "./RegistryHelp";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: "100%",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: "100%",
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    faq: {
      margin: "0px !important",
      border: "1px solid rgba(0, 0, 0, .125)",
      marginBottom: "0px",
      boxShadow: "none",
      "&:not(:first-child)": {
        borderTop: 0,
      },
      "&:before": {
        display: "none",
      },
      "&.Mui-expanded": {
        "&:not(:first-child)": {
          borderTop: "0px solid rgba(0, 0, 0, .125)",
        },
      },
    },
    summary: {
      minHeight: "48px !important",
    },
  }),
  { withTheme: true }
);

const faqs = [];
faqs.push({
  title: `How to use the tools`,
  body: (
    <React.Fragment>
      This help section explains how to use the tools provided on this page for
      things such as public / private key generation, message signing &
      verification, encryption & decryption, and hash creation & verification.
      These tools are provided to assist with the creation and validation of an{" "}
      {process.env.REACT_APP_BRAND}, but you can also use them as standalone
      tools for other purposes. Generated keys and signed and/or encrypted
      messages created by these tools can be used by other systems that support
      PGP. Likewise, these tools can be used to verify or decrypt messages
      created by other PGP systems or use keys generated by other PGP systems to
      sign and/or encrypt messages. In short, these tools can be used to create
      an {process.env.REACT_APP_BRAND}, but they can also be used for any other
      suitable purpose.
      <br />
      <br />
      <strong>PRIVACY IS IMPORTANT:</strong>
      <br />
      <br />
      Any generated keys and signed and/or encrypted messages generated by these
      tools will NEVER be sent across the internet. Your private key and
      password will NEVER be sent across the internet under ANY circumstances.
      You can openly monitor your network traffic after this page loads and you
      will see that the actions you take to generate keys and sign and/or
      encrypt messages all happen offline. In fact, you can load this page while
      online and then disconnect your device from the internet and you will
      still be able to use these tools to generate keys and sign and/or encrypt
      messages without an internet connection. You can save any generated keys
      or messages while offline and then close this page before reconnecting to
      the internet to be sure that your private data was never on your computer
      at the same time you were connected to the internet, ensuring that your
      keys and messages could not possibly have been tranmitted across the
      internet (to {process.env.REACT_APP_BRAND} or anyone else).
      <br />
      <br />
      <strong>Instructions:</strong>
      <br />
      <br />
      When it comes to signatures, verification, and encryption there are many
      ways to achieve similar or analogous results depending on the order in
      which you perform operations and the inputs you provide to the various
      fields. When reading the instructions be sure to consider that you can
      provide signed or unsigned and encrypted or unencrypted values to the
      fields. You can sign already encrypted messages and you can encrypt
      already signed messages. You can sign and/or encrypt messages that have
      already been encrypted and/or signed (in either order). This allows you to
      create messages that are signed for verification and contain an encrypted
      message or encrypted and contain a signed message that cannot be verified
      until it is decrypted to reveal the signature. You can achieve similar
      results for all of these cases by performing various iterations of the
      following steps:
      <br />
      <br />
      - Sign a message
      <br />
      - Encrypt a message
      <br />
      - Encrypt a message then sign it
      <br />
      - Sign a message then encrypt it
      <br />
      - Encrypt + Sign a message
      <br />
      <br />
      <ToolsHelp />
    </React.Fragment>
  ),
});

function FAQ(props) {
  const [expanded, setExpanded] = React.useState({ faq2: true });

  const handleChange = (faq) => (event, isExpanded) => {
    var new_exp = {};

    if (expanded[faq]) {
      new_exp[faq] = false;
    } else {
      new_exp[faq] = true;
    }

    var final_exp = {};

    //comment out to allow multiple accordions to be open simultaneously
    final_exp = Object.assign({}, new_exp);

    final_exp = new_exp;

    //console.log(final_exp);

    setExpanded(final_exp);
  };

  var classes = useStyles();

  return (
    <div className={classes.root}>
      {faqs.map((f, i) => {
        return (
          <Accordion
            expanded={expanded["faq" + i] ? expanded["faq" + i] : false}
            onChange={handleChange("faq" + i)}
            key={"faq" + i}
            TransitionProps={{
              timeout: 0,
            }}
            className={classes.faq + " faq"}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={"faq" + i + "bh-content"}
              id={"faq" + i + "bh-header"}
              className={classes.summary}
            >
              <Typography className={classes.heading + " bold"} variant="body2">
                {f.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant={"body2"} component="div">
                {f.body}
              </Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setAlert: (alert) => dispatch(setAlert(alert)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FAQ);
