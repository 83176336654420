import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { setUser, setAlert, setTemp, setCrypto } from "../actions";
import { Button, TextField, Grid } from "@material-ui/core";
import Crypto from "../Crypto";

const useStyles = makeStyles(
  (theme) => ({
    lookup_form: {
      width: "100%",
    },
    form_container: {
      marginTop: "16px",
    },
    button_container: {
      marginTop: theme.spacing(0.5),
    },
  }),
  { withTheme: true }
);

function Generate(props) {
  const classes = useStyles();

  const [value, setValue] = React.useState(props.crypto.hash);

  var new_crypto = Object.assign({}, props.crypto);

  var setCrypto = props.setCrypto;

  React.useEffect(() => {
    if (new_crypto.refresh_hash) {
      //setPassphrase(new_crypto.passphrase)
      setValue(new_crypto.hash);
      new_crypto.refresh_hash = false;
      setCrypto(new_crypto);
    }
  }, [new_crypto, setCrypto, setValue]);

  return (
    <React.Fragment>
      <Grid container alignItems="center" spacing={0}>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <TextField
              label="hash"
              multiline={true}
              minRows={4}
              maxRows={4}
              variant={process.env.REACT_APP_STYLE}
              fullWidth
              size="small"
              onChange={(e) => {
                var new_crypto = Object.assign({}, props.crypto);
                new_crypto.hash = e.target.value;

                setValue(e.target.value);
                if (window.typing) {
                  clearTimeout(window.typing);
                }
                window.typing = setTimeout(() => {
                  props.setCrypto(new_crypto);
                }, 100);
              }}
              value={value}
              type="text"
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={1} className={classes.button_container}>
        <Grid item xs={props.public === false ? 6 : 4}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="small"
            fullWidth
            onClick={async () => {
              var hash = await Crypto.hash(props.crypto.hash, "sha256");
              var new_crypto = Object.assign({}, props.crypto);
              new_crypto.hash = hash;
              new_crypto.refresh_hash = true;
              props.setCrypto(new_crypto);
            }}
            disabled={!props.crypto.hash ? true : false}
          >
            Create SHA-256
          </Button>
        </Grid>
        <Grid item xs={props.public === false ? 6 : 4}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="small"
            fullWidth
            onClick={async () => {
              var hash = await Crypto.hash(props.crypto.hash, "md5");
              var new_crypto = Object.assign({}, props.crypto);
              new_crypto.hash = hash;
              new_crypto.refresh_hash = true;
              props.setCrypto(new_crypto);
            }}
            disabled={!props.crypto.hash ? true : false}
          >
            Create md5
          </Button>
        </Grid>
        {props.public === false ? null : (
          <Grid item xs={4}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="small"
              fullWidth
              onClick={async () => {
                var new_crypto = Object.assign({}, props.crypto);

                var isMD5 = (s) => {
                  return s.match(/[a-fA-F0-9]{32}/);
                };

                var isSha256 = (s) => {
                  return s.match(/[a-fA-F0-9]{64}/);
                };

                if (isSha256(props.crypto.hash)) {
                  var extended_message =
                    props.crypto.input.trim() +
                    "\r\n\r\nSHA-256 attached hash value:\r\n" +
                    props.crypto.hash;
                } else if (isMD5(props.crypto.hash)) {
                  extended_message =
                    props.crypto.input.trim() +
                    "\r\n\r\nMD5 attached hash value:\r\n" +
                    props.crypto.hash;
                } else {
                  extended_message =
                    props.crypto.input.trim() +
                    "\r\n\r\nattached hash value:\r\n" +
                    props.crypto.hash;
                }
                new_crypto.refresh_input = true;
                new_crypto.input = extended_message;
                props.setCrypto(new_crypto);
              }}
              disabled={!props.crypto.hash ? true : false}
            >
              Insert Hash
            </Button>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  temp: state.temp,
  crypto: state.crypto,
});

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(setUser(user)),
  setAlert: (alert) => dispatch(setAlert(alert)),
  setTemp: (temp) => dispatch(setTemp(temp)),
  setCrypto: (crypto) => dispatch(setCrypto(crypto)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Generate);
