import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { connect } from "react-redux";
import { setAlert } from "../actions";

const useStyles = makeStyles(
	(theme) => ({
		root: {
			width: "100%",
		},
		heading: {
			fontSize: theme.typography.pxToRem(15),
			flexBasis: "100%",
			flexShrink: 0,
		},
		secondaryHeading: {
			fontSize: theme.typography.pxToRem(15),
			color: theme.palette.text.secondary,
		},
		faq: {
			margin: "0px !important",
			border: "1px solid rgba(0, 0, 0, .125)",
			marginBottom: "0px",
			boxShadow: "none",
			"&:not(:first-child)": {
				borderTop: 0,
			},
			"&:before": {
				display: "none",
			},
			"&.Mui-expanded": {
				"&:not(:first-child)": {
					borderTop: "0px solid rgba(0, 0, 0, .125)",
				},
			},
		},
		between: {
			marginTop: "8px !important",
			marginBottom: "0px !important",
			paddingBottom: "8px",
			borderLeft: "0px",
			borderRight: "0px",
			borderBottom: "1px solid rgba(0, 0, 0, .125)",
		},
		summary: {
			minHeight: "48px !important",
		},
		content: {},
		divider: {
			borderBottom: "1px solid rgba(0, 0, 0, .125)",
		},
	}),
	{ withTheme: true }
);

const faqs = [];
faqs.push(
	{
		title: `Confirming ownership identity`,
		body: (
			<React.Fragment>
				Each mark that is submitted to the registry will be automatically signed
				using the public key belonging to the entity submitting the content.
				This signature proves that the mark was submitted by a specific entity.
				<br />
				<br />
				Public keys themselves can be submitted by their owner to the registry
				as a piece of content just like anything else. The owner can attach any
				arbitrary or identifying information they want to a public key's
				registry entry for others to see when they lookup that key in the
				registry. This allows the owner of a public key to attach as much or as
				little identifying information in whatever capacity they choose to their
				key at any time they want. This information can be used by the owner to
				prove that they are the entity who submitted a piece of content at a
				specific time on a specific date.
				<br />
				<br />
				Anyone can lookup a public key in the registry and view whatever
				information the owner of that key has chosen to attach to it. If a key
				has been submitted to the registry with identifying information attached
				then that information can be used to confirm the identity of the owner
				of that key and, by extension, any marks that have been submitted to
				the registry using that key.
				<br />
				<br />
				Each mark's registry entry page provides a link to the registry entry
				for the public key that submitted the mark so that the viewer can
				identify the owner of the mark and view any information that the owner
				of the mark has chosen to attach to the public key's registry entry, if any.
			</React.Fragment>
		),
	},
	{
		title: `Verifying content`,
		body: (
			<React.Fragment>
				Verification that a piece of content was submitted to the registry at a
				specific time on a specific date by a specific entity does not always
				indicate legal ownership of the content by the entity who submitted it,
				but it does indicate if that entity is the first to submit that content
				to the registry (or elsewhere). Additionally, timestamp verification can
				be used to indicate if the submission of a piece of content to the
				registry preceeded a specific time and date that another entity claims
				is when they had first created, used, or registered the content in
				question.
				<br />
				<br />
				For example:
				<br />
				Entity A submits a piece of content to the registry.{" "}
				{process.env.REACT_APP_BRAND} records that submission as a permanent
				mark. Entity B then lays claim to that piece of content. Entity A can
				prove when they first submitted the content in question to the registry
				by sharing the receipt for their mark. If Entity A's registry entry was
				created before the earliest time that Entity B can demonstrate that they
				first used the content in question then Entity A has, at the very least,
				a modicum of support for validation of their claim to be the owner of
				the content in question. Even if Entity A has not officially registered
				the content for copyright, trademark, or patent with the appropriate
				legal jurisdictions, they will at least have a timestamped proof of
				their submission of the content to the registry provided by an arbitrary
				and impartial third-party actor, {process.env.REACT_APP_BRAND}. This
				proof may be used to bolster Entity A's claim to be the owner or
				originator of a piece of content.
			</React.Fragment>
		),
	}
);

function FAQ(props) {
	const [expanded, setExpanded] = React.useState({});

	const handleChange = (faq) => (event, isExpanded) => {
		var new_exp = {};

		if (expanded[faq]) {
			new_exp[faq] = false;
		} else {
			new_exp[faq] = true;
		}

		var final_exp = {};

		//comment out to allow multiple accordions to be open simultaneously
		final_exp = Object.assign({}, new_exp);

		final_exp = new_exp;

		setExpanded(final_exp);
	};

	var classes = useStyles();

	var prev = "content";

	return (
		<div className={classes.root}>
			{faqs.map((f, i) => {
				if (f.body) {
					prev = "content";
					return (
						<Accordion
							expanded={expanded["faq" + i] ? expanded["faq" + i] : false}
							onChange={handleChange("faq" + i)}
							key={"faq" + i}
							TransitionProps={{
								timeout: 0,
							}}
							className={classes.faq + " faq subSection"}
						>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls={"faq" + i + "bh-content"}
								id={"faq" + i + "bh-header"}
								className={classes.summary}
							>
								<Typography
									className={classes.heading + " bold"}
									variant="body2"
								>
									{f.title}
								</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Typography variant={"body2"} component="div">
									{f.body}
								</Typography>
							</AccordionDetails>
						</Accordion>
					);
				} else {
					prev = "divider";
					return (
						<div
							key={"faq" + i}
							className={
								"faq " + classes.between + " " + classes[prev] + " " + prev
							}
						>
							<Typography
								component={"p"}
								variant="body2"
								style={{ textAlign: f.align ? f.align : "left" }}
							>
								{f.title}
							</Typography>
						</div>
					);
				}
			})}
		</div>
	);
}

const mapStateToProps = (state) => ({
	user: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
	setAlert: (alert) => dispatch(setAlert(alert)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FAQ);
