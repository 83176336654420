import store from "./reducers/index";
import { setCrypto } from "./actions";
import Moment from "moment-timezone";
import md5 from "md5";

const _crypto = {
	private_key: ``,
	passphrase: ``,
	public_key: ``,
	public_key_id: "",
	hash: "",
	name: ``,
	email: "",
	recipient: "",
	sender: "",
	signature: "",
	extended_message: "",
	verification: {},
	verified: null,
	input: "",
	output: "",
	decrypted: "",
	decrypted_signed: "",
	bits: 4096,
	creation_time: "",
	comments: "",
	registry_entry: "",
	stored: false,
	lookupKey: "",
	lookupKeyFound: "",
	lookupKeyFingerprint: "",
	keyFound: "",
	notFound: "",
	mark_id: "",
	lookupMark: "",
	foundMark: "",
};

const Crypto = {};

const public_key = process.env.public_key;

Crypto.generate = async (bits) => {
	var new_crypto = Object.assign({}, store.getState().crypto);

	if (new_crypto.email && new_crypto.email.trim().length > 0) {
		var uids = [
			{
				name: new_crypto.name.trim(),
				email: new_crypto.email.trim(),
			},
		];
	} else {
		uids = [
			{
				name: new_crypto.name ? new_crypto.name.trim() : "",
			},
		];
	}

	var options = {
		userIds: uids,
		numBits: bits ? bits : new_crypto.bits,
		passphrase: new_crypto.passphrase.trim(),
		neverExpires: 1,
	};

	return window.openpgp.generateKey(options).then((key) => {
		var privkey = key.privateKeyArmored;
		var pubkey = key.publicKeyArmored;
		//var revocationSignature = key.revocationSignature;

		new_crypto.private_key = privkey.trim();
		new_crypto.public_key = pubkey.trim();
		new_crypto.revocationSignature = key.revocationSignature;

		setCrypto(new_crypto);

		return new Promise(async (resolve, reject) => {
			resolve(new_crypto);
		});
	});
};

Crypto.extract = async () => {
	var new_crypto = Object.assign({}, store.getState().crypto);

	var pk = await window.openpgp.key.readArmored(new_crypto.public_key);

	if (!pk.keys[0]) {
		new_crypto.public_key_id = "";

		store.dispatch(setCrypto(new_crypto));

		return false;
	}

	var pk_id = pk.keys[0].keyPacket.keyid.toHex();

	new_crypto.public_key_id = pk_id;

	store.dispatch(setCrypto(new_crypto));

	return new Promise(async (resolve, reject) => {
		resolve(new_crypto);
	});
};

Crypto.extract_id = async (key) => {
	var pk = await window.openpgp.key.readArmored(key);

	if (!pk.keys[0]) {
		return false;
	}

	var pk_id = pk.keys[0].keyPacket.keyid.toHex();

	//console.log(Buffer.from(pk.keys[0].keyPacket.fingerprint).toString('hex'));

	return new Promise(async (resolve, reject) => {
		resolve(pk_id);
	});
};

Crypto.signMessage = async (message, detached, options) => {
	var new_crypto = Object.assign({}, store.getState().crypto);
	var cleartext;

	var passphrase = new_crypto.passphrase;

	return window.openpgp.key
		.readArmored(new_crypto.private_key.trim())
		.then((keyArm) => {
			if (!passphrase) {
				var opts = {
					message: window.openpgp.cleartext.fromText(message),
					privateKeys: [keyArm.keys[0]],
					detached: detached ? true : false,
				};

				var creation_time = Moment().valueOf();

				opts.date = options.creation_time
					? options.creation_time
					: creation_time;

				opts.keyExpirationTime = 0;
				opts.neverExpires = 1;

				return window.openpgp
					.sign(opts)
					.then(function (signed) {
						cleartext = signed.signature;

						if (cleartext) {
							var final = cleartext.trim();
						}

						if (signed.data) {
							final = signed.data;
						}

						return new Promise((resolve, reject) => {
							resolve(final);
						});
					})
					.catch((err) => {
						return new Promise((resolve, reject) => {
							reject("invalid passphrase");
						});
					});
			}

			return keyArm.keys[0]
				.decrypt(passphrase)
				.then((data) => {
					var opts = {
						message: window.openpgp.cleartext.fromText(message),
						privateKeys: [keyArm.keys[0]],
						detached: detached ? true : false,
					};

					var creation_time = Moment().valueOf();

					opts.date = options.creation_time
						? options.creation_time
						: creation_time;

					var date_format = Moment(opts.date)
						.format("YYYY-MM-DDTHH:mm:ss.SSSSSSS")
						.valueOf();

					var new_date = Number(new Date(date_format));

					opts.date = new_date;

					var final_date = Moment(opts.date).milliseconds(0).valueOf();

					opts.date = final_date;

					opts.keyExpirationTime = 0;
					opts.neverExpires = 1;

					return window.openpgp.sign(opts).then(function (signed) {
						cleartext = signed.signature;

						if (cleartext) {
							var final = cleartext.trim();
						}

						if (signed.data) {
							final = signed.data;
						}

						return new Promise((resolve, reject) => {
							resolve(final);
						});
					});
				})
				.catch((err) => {});
		});
};

Crypto.verifyMessage = (signed, opts) => {
	var new_crypto = Object.assign({}, store.getState().crypto);
	var validity;

	return window.openpgp.cleartext
		.readArmored(signed)
		.then((message) => {
			var options = {};
			options.message = message;

			return window.openpgp.key
				.readArmored(new_crypto.sender)
				.then((pubkeyArm) => {
					options.publicKeys = pubkeyArm.keys;

					return window.openpgp.verify(options).then(function (verified) {
						//console.log(verified)

						validity = verified.signatures[0].valid;
						window.sign = verified.signatures[0];
						if (validity) {
							var ret = {
								signed_by: verified.signatures[0].keyid.toHex(),
								fingerprint: Buffer.from(
									verified.signatures[0].signature.packets[0].issuerFingerprint
								).toString("hex"),
								message: message.text,
							};
						} else if (validity === false) {
							ret = {};
							ret.error = "invalid signature";
						} else {
							ret = {};
							ret.error = "invalid signature";
						}

						return new Promise((resolve, reject) => {
							resolve(ret);
						});
					});
				});
		})
		.catch((err) => {
			var ret = {};
			ret.error = "invalid format";
			return new Promise((resolve, reject) => {
				resolve(ret);
			});
		});
};

Crypto.verifyServerMessage = (signed, opts) => {
	var validity;

	return window.openpgp.cleartext.readArmored(signed).then((message) => {
		var options = {};
		options.message = message;

		return window.openpgp.key.readArmored(public_key).then((pubkeyArm) => {
			options.publicKeys = pubkeyArm.keys;

			return window.openpgp.verify(options).then(function (verified) {
				validity = verified.signatures[0];
				window.sign = verified.signatures[0];
				if (validity) {
					var ret = {
						signed_by: verified.signatures[0].keyid.toHex(),
						message: message.text,
					};
				} else {
					ret = {};
					ret.error = "invalid signature";
				}

				return new Promise((resolve, reject) => {
					resolve(ret);
				});
			});
		});
	});
};

Crypto.signDetached = async (message, creation_time) => {
	var new_crypto = Object.assign({}, store.getState().crypto);

	if (new_crypto.hash.length > 0 && 1 === 0) {
		var signed = await Crypto.signMessage(
			message.trim() +
				"\r\n\r\nSHA-256 hash include value of this signed message:\r\n" +
				new_crypto.hash,
			true,
			{ creation_time: creation_time }
		);
		var extended_message =
			message.trim() +
			"\r\n\r\nSHA-256 hash include value of this signed message:\r\n" +
			new_crypto.hash;
	} else {
		if (new_crypto.recipient && 1 === 0) {
			var recipient_hash = await window.sha256(new_crypto.recipient);

			extended_message =
				message.trim() +
				"\r\n\r\nSHA-256 hash of public key of original intended recipient of this signed message:\r\n" +
				recipient_hash;

			signed = await Crypto.signMessage(extended_message, true, {
				creation_time: creation_time,
			});
		} else {
			signed = await Crypto.signMessage(message.trim(), true, {
				creation_time: creation_time,
			});
		}
	}

	return new Promise((resolve, reject) => {
		resolve({ signed: signed, extended_message: extended_message });
	});
};

Crypto.verifySignature = async (message, signature, opts) => {
	var new_crypto = Object.assign({}, store.getState().crypto);

	//var k = (await window.openpgp.key.readArmored(new_crypto.sender)).keys

	var verified = null;

	try {
		verified = await window.openpgp.verify({
			message: window.openpgp.cleartext.fromText(message),
			signature: await window.openpgp.signature.readArmored(signature),
			publicKeys: (await window.openpgp.key.readArmored(new_crypto.sender))
				.keys,
		});
	} catch (e) {
		var ret = {};
					ret.error = "invalid format";
		return new Promise((resolve, reject) => {
			resolve(ret);
		});
	}

	const valid = verified.signatures[0].valid;

	if (valid) {
		/*
        var pk = await window.openpgp.key.readArmored(new_crypto.sender)
    
        var pk_id = pk.keys[0].keyPacket.keyid.toHex()
    
        var match =  'does not match'
        if (pk_id === verified.signatures[0].keyid.toHex()) {
          match = 'matches'
        }
    */

		//var match = 'matches'

		var verification = {};

		verification.signed_by = verified.signatures[0].keyid.toHex();
		verification.fingerprint = Buffer.from(
			verified.signatures[0].signature.packets[0].issuerFingerprint
		).toString("hex");
		verification.original = message;
	} else {
		verification = {};
		verification.signed_by = "invalid signature";
		verification.fingerprint = null;
	}

	return new Promise((resolve, reject) => {
		resolve(verification);
	});
};

Crypto.hash = async (message, type) => {
	if (type === "md5") {
		var hash = md5(message.trim());
	} else {
		hash = await window.sha256(message);
	}

	return new Promise((resolve, reject) => {
		resolve(hash);
	});
};

Crypto.encrypt = async (message, hash) => {
	return Crypto._encrypt(message, hash).then((encrypted) => {
		return new Promise((resolve, reject) => {
			resolve(encrypted);
		});
	});
};

Crypto._encrypt = async (message, hash) => {
	var new_crypto = Object.assign({}, store.getState().crypto);
	var passphrase = new_crypto.passphrase;

	if (hash && hash.length > 0) {
		var extended_message =
			message.trim() +
			"\r\n\r\nSHA-256 hash of original intended recipient's public key:\r\n" +
			hash;
		message = extended_message;
	} else {
		extended_message = message;
	}
	var stop = false;

	const privKeyObj = (
		await window.openpgp.key.readArmored(new_crypto.private_key)
	).keys[0];

	await privKeyObj.decrypt(passphrase).catch((err) => {
		stop = true;
		return new Promise((resolve, reject) => {
			reject("invalid passphrase");
		});
	});

	if (stop) {
		return new Promise((resolve, reject) => {
			reject("invalid passphrase");
		});
	}

	const options = {
		message: window.openpgp.message.fromText(message),
		publicKeys: (await window.openpgp.key.readArmored(new_crypto.recipient))
			.keys,
		privateKeys: [privKeyObj],
	};

	return window.openpgp.encrypt(options).then((ciphertext) => {
		var encrypted = ciphertext.data;
		return new Promise((resolve, reject) => {
			resolve({ encrypted: encrypted, extended_message: extended_message });
		});
	});
};

Crypto.encryptPublic = (message) => {
	return Crypto._encryptPublic(message, function (encrypted) {
		return new Promise((resolve, reject) => {
			resolve(encrypted.trim());
		});
	});
};

Crypto._encryptPublic = async (message, callback) => {
	var new_crypto = Object.assign({}, store.getState().crypto);
	const options = {
		message: window.openpgp.message.fromText(message),
		publicKeys: (await window.openpgp.key.readArmored(new_crypto.recipient))
			.keys,
	};

	return window.openpgp.encrypt(options).then((ciphertext) => {
		var encrypted = ciphertext.data;

		return new Promise((resolve, reject) => {
			resolve(encrypted.trim());
		});
	});
};

Crypto.decryptPublic = (message) => {
	return Crypto._decryptPublic(message).then((plaintext) => {
		return new Promise((resolve, reject) => {
			resolve(plaintext.trim());
		});
	});
};

Crypto._decryptPublic = async (message, callback) => {
	var new_crypto = Object.assign({}, store.getState().crypto);
	var passphrase = new_crypto.passphrase;

	const privKeyObj = (
		await window.openpgp.key.readArmored(new_crypto.private_key)
	).keys[0];
	await privKeyObj.decrypt(passphrase);

	const options = {
		message: await window.openpgp.message.readArmored(message),
		privateKeys: [privKeyObj],
	};

	return window.openpgp.decrypt(options).then((plaintext) => {
		return new Promise((resolve, reject) => {
			resolve(plaintext.data);
		});
	});
};

Crypto.decrypt = (message) => {
	return Crypto._decrypt(message).then((plaintext) => {
		return new Promise((resolve, reject) => {
			resolve(plaintext);
		});
	});
};

Crypto._decrypt = async (message, callback) => {
	var new_crypto = Object.assign({}, store.getState().crypto);
	var passphrase = new_crypto.passphrase;

	const privKeyObj = (
		await window.openpgp.key.readArmored(new_crypto.private_key)
	).keys[0];
	await privKeyObj.decrypt(passphrase);

	var keys = await window.openpgp.key.readArmored(new_crypto.sender);

	const options = {
		message: await window.openpgp.message.readArmored(message),
		publicKeys: keys.keys,
		privateKeys: [privKeyObj],
	};

	return window.openpgp.decrypt(options).then((plaintext) => {
		return new Promise((resolve, reject) => {
			resolve(plaintext);
		});
	});
};

Crypto.reset = async () => {
	var new_crypto = Object.assign({}, _crypto);
	setCrypto(new_crypto);
};

export default Crypto;
